import axios, { AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: 'https://dev.online-mall.store/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Define types for the data structures
interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

interface LoginCredentials {
  email: string;
  password: string;
  rememberMe?: boolean;
}

interface AuthResponse {
  token: string;
  user: {
    id: number;
    name: string;
    email: string;
   
  };
}

export const registerUser = async (userData: UserData): Promise<AxiosResponse<AuthResponse>> => {
  return api.post<AuthResponse>('/auth/register', userData);
};

export const loginUser = async (credentials: LoginCredentials): Promise<AxiosResponse<AuthResponse>> => {
  return api.post<AuthResponse>('/auth/login', credentials);
};

export default api;
