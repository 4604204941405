import React, { useState, ChangeEvent, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  selectAuthStatus,
  selectAuthError,
} from "../../store/slices/authSlice";
import { AppDispatch, RootState } from "../../store/store";
import logo from "../img/logo2.png";

interface LoginFormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

const LoginForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authStatus = useSelector((state: RootState) => selectAuthStatus(state));
  const authError = useSelector((state: RootState) => selectAuthError(state));

  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [localErrors, setLocalErrors] = useState<Record<string, string>>({});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleApiErrors = (
    status: number | undefined,
    message: string | undefined
  ) => {
    switch (status) {
      case 400:
        setLocalErrors({ email: "Email and password are required." });
        break;
      case 404:
        setLocalErrors({ email: "User not found." });
        break;
      case 401:
        setLocalErrors({ password: "Incorrect password." });
        break;
      case 503:
        setLocalErrors({
          email: "Database access issue, please try again later.",
        });
        break;
      case 500:
        setLocalErrors({
          email: "An unexpected error occurred. Please try again.",
        });
        break;
      default:
        setLocalErrors({ email: message || "An unknown error occurred." });
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const resultAction = await dispatch(login(formData));
      if (login.fulfilled.match(resultAction)) {
        alert("Login successful!");
      } else if (login.rejected.match(resultAction)) {
        const { status, message } = (resultAction.payload || {}) as Record<
          string,
          any
        >;
        handleApiErrors(status, message);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  return (
    <div className="pt-[20px] flex justify-center items-center min-h-screen bg-gray-300">
      <div className="w-[35%] bg-gray-200 pt-0 pb-14 pl-20 pr-20 rounded-lg shadow-lg">
        <div className="bg-gray-200 w-[45%] shadow-[0px_0px_15px_0px_rgba(0,0,0,0.11)] flex justify-center items-center mx-auto p-4 rounded-lg mb-[30px] -mt-[40px]">
          <img src={logo} alt="logo" />
        </div>
        <form
          onSubmit={handleSubmit}
          className="bg-gray-100 p-8 rounded-lg shadow-lg"
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 font-medium mb-1"
              htmlFor="email"
            >
              Email address
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow p-2 w-full rounded"
              required
            />
            {localErrors.email && (
              <p className="text-red-500">{localErrors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 font-medium mb-1"
              htmlFor="password"
            >
              Password
            </label>
            <input
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="shadow p-2 w-full rounded"
              required
            />
            {localErrors.password && (
              <p className="text-red-500">{localErrors.password}</p>
            )}
          </div>
          <label className="flex items-center mb-4">
            <input
              type="checkbox"
              name="rememberMe"
              checked={formData.rememberMe}
              onChange={handleChange}
              className="mr-2"
            />
            Remember me
          </label>
          <button
            type="submit"
            className="bg-blue-600 text-white p-2 rounded w-full hover:bg-blue-700"
            disabled={authStatus === "loading"}
          >
            {authStatus === "loading" ? "Logging in..." : "Login"}
          </button>
        </form>
        {authStatus === "failed" && (
          <p className="text-red-500 text-center mt-2">{authError}</p>
        )}
        <h3 className="text-center mt-[20px]">
          Don’t have an account?
          <span className="text-blue-500 cursor-pointer">Register</span>
        </h3>
      </div>
    </div>
  );
};

export default LoginForm;
