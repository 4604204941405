// components/Header.jsx

import { useState } from 'react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-blue-600 text-white">
      <div className="container mx-auto flex items-center justify-between p-4">
        
        {/* Logo */}
        <div className="text-2xl font-bold">
          <a href="/">My Kiosk</a>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6">
          <a href="#home" className="hover:text-blue-300">Home</a>
          <a href="#products" className="hover:text-blue-300">Products</a>
          <a href="#about" className="hover:text-blue-300">About</a>
          <a href="#contact" className="hover:text-blue-300">Contact</a>
        </nav>
        <button
          className="md:hidden flex items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Navigation */}
      {isOpen && (
        <nav className="md:hidden bg-blue-500 p-4">
          <a href="#home" className="block py-2 hover:text-blue-300">Home</a>
          <a href="#products" className="block py-2 hover:text-blue-300">Products</a>
          <a href="#about" className="block py-2 hover:text-blue-300">About</a>
          <a href="#contact" className="block py-2 hover:text-blue-300">Contact</a>
        </nav>
      )}
    </header>
  );
};

export default Header;
