
import "./styles/globals.scss";
import Login from "./components/login/login";
import RegistrationForm from './components/register/register';
import Header from './components/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <Login />
      <RegistrationForm />
    </div>
  );
}

export default App;
