import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register, selectAuthStatus, selectAuthError } from '../../store/slices/authSlice';
import { AppDispatch, RootState } from '../../store/store';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsAccepted: boolean;
}

const RegistrationForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authStatus = useSelector((state: RootState) => selectAuthStatus(state));
  const authError = useSelector((state: RootState) => selectAuthError(state));

  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false,
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validate = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.firstName) {
      newErrors.firstName = "First Name is required.";
    } else if (formData.firstName.length < 2 || formData.firstName.length > 50) {
      newErrors.firstName = "First Name must be between 2 and 50 characters.";
    } else if (!/^[A-Za-z]+$/.test(formData.firstName)) {
      newErrors.firstName = "First Name must contain only alphabetic characters.";
    }

    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required.";
    } else if (formData.lastName.length < 2 || formData.lastName.length > 50) {
      newErrors.lastName = "Last Name must be between 2 and 50 characters.";
    } else if (!/^[A-Za-z]+$/.test(formData.lastName)) {
      newErrors.lastName = "Last Name must contain only alphabetic characters.";
    }

    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email must be a valid format (e.g., user@example.com).";
    }

    if (!formData.password) {
      newErrors.password = "Password is required.";
    } else if (formData.password.length < 8 || formData.password.length > 128) {
      newErrors.password = "Password must be between 8 and 128 characters.";
    } else if (!/[A-Z]/.test(formData.password)) {
      newErrors.password = "Password must contain at least one uppercase letter.";
    } else if (!/[a-z]/.test(formData.password)) {
      newErrors.password = "Password must contain at least one lowercase letter.";
    } else if (!/\d/.test(formData.password)) {
      newErrors.password = "Password must contain at least one number.";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)) {
      newErrors.password = "Password must contain at least one special character.";
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required.";
    } else if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = "Passwords must match.";
    }

    if (!formData.termsAccepted) {
      newErrors.termsAccepted = "You must accept the terms of service.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (validate()) {
      dispatch(register(formData));
    }
  };

  return (
    <div className="pt-[20px] flex justify-center items-center min-h-screen bg-gray-300">
      <div className="bg-gray-200 pt-0 pb-14 pl-20 pr-20 rounded-lg shadow-lg">
        <div className="w-[45%] shadow-lg flex justify-center items-center mx-auto p-4 rounded-lg mb-[30px] -mt-[40px]">
          {/* <img src={logo} alt="logo" /> */}
        </div>
        <form onSubmit={handleSubmit} className="bg-gray-100 p-8 rounded-lg shadow-lg">
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label className="block text-gray-700 font-medium mb-1" htmlFor="firstName">
                First Name
              </label>
              <input
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="shadow p-2 w-full rounded"
                required
              />
              {errors.firstName && <p className="text-red-500">{errors.firstName}</p>}
            </div>
            <div className="w-1/2 pl-2">
              <label className="block text-gray-700 font-medium mb-1" htmlFor="lastName">
                Last Name
              </label>
              <input
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="shadow p-2 w-full rounded"
                required
              />
              {errors.lastName && <p className="text-red-500">{errors.lastName}</p>}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-1" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow p-2 w-full rounded"
              required
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-1" htmlFor="password">
              Password
            </label>
            <input
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="shadow p-2 w-full rounded"
              required
            />
            {errors.password && <p className="text-red-500">{errors.password}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-1" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <input
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="shadow p-2 w-full rounded"
              required
            />
            {errors.confirmPassword && <p className="text-red-500">{errors.confirmPassword}</p>}
          </div>
          <label className="flex items-center mb-4">
            <input
              type="checkbox"
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={handleChange}
              className="mr-2"
            />
            I have read and agree to the terms of service
          </label>
          {errors.termsAccepted && <p className="text-red-500">{errors.termsAccepted}</p>}
          <button type="submit" className="bg-blue-600 text-white p-2 rounded w-full hover:bg-blue-700">
            Register
          </button>
        </form>
        {authStatus === 'failed' && <p className="text-red-500 text-center mt-2">{authError}</p>}
      </div>
    </div>
  );
};

export default RegistrationForm;
